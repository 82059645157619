body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  background-color: #1F1F1F;
  color: white;
  font-family: 'Lato', sans-serif;
}

.App {
  height: 600px;
  margin: 40px auto;
  margin-top: 0;
  padding: 20px;
  text-align: center;
  width: 800px;
}

.heading {
  font-size: 50px;
  margin-bottom: 20px;
}

.buttonbar li {
  display: inline-block;
  margin: 0 10px;
}

.buttonbar button {
  border: 2px solid grey;
  background-color: black;
  color: white;
  height: 40px;
  width: 80px;
}

.board {
  margin: 10px auto;
}

.row {
  height: 10px;
  width: 800px;
}

.cell {
  border: 1px solid black;
  display: inline-block;
  height: 9px;
  vertical-align: top;
  width: 9px;
}

.show {
  background-color: red;
}

.generation {
  font-size: 20px;
  margin: 20px;
}
